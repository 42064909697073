export const REPORT_URLS = {
  agro: '/reports/agro/analysis',
  apparel: '/reports/apparel',
  banks: '/reports/banks/analysis',
  'brazil-activity-anfavea': '/reports/macro/brazil_activity_anfavea',
  'brazil-activity-forecasts': '/reports/macro/brazil_activity_forecasts',
  'brazil-activity-gdp': '/reports/macro/brazil_activity_gdp',
  'brazil-activity-pim': '/reports/macro/brazil_activity_pim',
  'brazil-activity-pmc': '/reports/macro/brazil_activity_pmc',
  'brazil-activity-pms': '/reports/macro/brazil_activity_pms',
  'brazil-bcb-pequeno-porte': '/reports/macro/brazil_bcb_pequeno_porte',
  'brazil-bop': '/reports/macro/brazil_bop',
  'brazil-credit': '/reports/macro/brazil_credit',
  'brazil-energy-ccee': '/reports/macro/brazil_energy_ccee',
  'brazil-fiscal': '/reports/macro/brazil_fiscal',
  'brazil-focus-expectations': '/reports/macro/brazil_focus_expectations',
  'brazil-icf-bcb': '/reports/macro/brazil_icf_bcb',
  'brazil-jgp-expectations': '/reports/macro/brazil_jgp_expectations',
  'brazil-labour-market': '/reports/macro/brazil_labour_market',
  'brazil-pre-copom': '/reports/macro/brazil_pre_copom',
  'brazil-prices-fgv': '/reports/macro/brazil_prices_fgv',
  'brazil-prices-fipe': '/reports/macro/brazil_prices_fipe',
  'brazil-prices-inflation-monitor':
    '/reports/macro/brazil_prices_inflation_monitor',
  'brazil-prices-ipca': '/reports/macro/brazil_prices_ipca',
  'brazil-prices-ipca-15': '/reports/macro/brazil_prices_ipca_15',
  'brazil-prices-ipca-encadeado':
    '/reports/macro/brazil_prices_ipca_encadeados',
  'brazil-prices-ipca-models': '/reports/macro/brazil_prices_ipca_models',
  'brazil-realtime-icbr': '/reports/macro/brazil_realtime_icbr',
  'brazil-surveys-fgv': '/reports/macro/brazil_surveys_fgv',
  'capital-markets': '/reports/capital_markets',
  'car-rental': '/reports/car_rental',
  'china-activity-proxy': '/reports/macro/china_activity_proxy',
  commodities: '/reports/commodities',
  consumer: '/reports/consumer',
  'consumer-monitor': '/reports/consumer_monitor',
  'country-australia': '/reports/macro/country_australia',
  'country-canada': '/reports/macro/country_canada',
  'country-chile': '/reports/macro/country_chile',
  'country-china': '/reports/macro/country_china',
  'country-colombia': '/reports/macro/country_colombia',
  'country-euro-area': '/reports/macro/country_euro_area',
  'country-germany': '/reports/macro/country_germany',
  'country-india': '/reports/macro/country_india',
  'country-japan': '/reports/macro/country_japan',
  'country-mexico': '/reports/macro/country_mexico',
  'country-new-zealand': '/reports/macro/country_new_zealand',
  'country-norway': '/reports/macro/country_norway',
  'country-russia': '/reports/macro/country_russia',
  'country-south-africa': '/reports/macro/country_south_africa',
  'country-south-korea': '/reports/macro/country_south_korea',
  'country-sweden': '/reports/macro/country_sweden',
  'country-united-kingdom': '/reports/macro/country_united_kingdom',
  'country-united-states': '/reports/macro/country_united_states',
  credit: '/reports/credit',
  crypto: '/reports/crypto',
  cvm: '/reports/cvm/analysis',
  distribution: '/reports/distribution',
  'earnings-surprise': '/reports/earnings_surprise',
  'ecommerce-amazon': '/reports/ecommerce/amazon',
  'ecommerce-enjoei': '/reports/ecommerce/enjoei',
  'ecommerce-meli': '/reports/ecommerce/meli',
  'ecommerce-rothys': '/reports/ecommerce/rothys',
  'ecommerce-sellers-meli': '/reports/ecommerce/sellers_meli',
  'ecommerce-valuation': '/reports/ecommerce/valuation',
  'ecommerce-westwing': '/reports/ecommerce/westwing',
  'economic-releases': '/reports/macro/economic-releases',
  'education-valuation': '/reports/education/valuation',
  'em-activity': '/reports/macro/em_activity',
  'em-chile': '/reports/macro/em_chile',
  'em-comparison': '/reports/macro/em_comparison',
  'em-colombia': '/reports/macro/em_colombia',
  'em-mexico': '/reports/macro/em_mexico',
  'em-monetary-policy-meetings': '/reports/macro/em_monetary_policy_meetings',
  'em-south-africa': '/reports/macro/em_south_africa',
  'energy-shortage-overview': '/reports/energy_shortage',
  exchange: '/reports/exchange',
  'financial-fraud': '/reports/financial_fraud',
  'financial-statement-financials': '/reports/financial_statement/financials',
  'financial-statement-ibov': '/reports/financial_statement/ibov',
  'financial-statement-non-financial':
    '/reports/financial_statement/non_financial',
  flow: '/reports/flow',
  'fx-and-rm-beer': null,
  'fx-and-rm-bond': null,
  'fx-and-rm-flows-and-positions': null,
  'global-scenario': '/reports/global_scenario',
  'gr-bis-credit': '/reports/macro/gr_bis_credit',
  'gr-citi-economic-surprise': '/reports/macro/gr_citi_surprise',
  'gr-cpi': '/reports/macro/gr_cpi',
  'gr-gdp-weekly-tracker': '/reports/macro/gr_gdp_weekly_tracker',
  'gr-hlw-models': '/reports/macro/gr_hlw_models',
  'gr-industrial-production': '/reports/macro/gr_industrial_production',
  'gr-leading-indicators': '/reports/macro/gr_leading_indicators',
  'gr-manufacturing-production': '/reports/macro/gr_manufacturing_production',
  'gr-nairu': '/reports/macro/gr_nairu',
  'gr-nowcast': '/reports/macro/gr_nowcast',
  'gr-pmi': '/reports/macro/gr_pmi',
  'gr-policy-rate': '/reports/macro/gr_policy_rate',
  'gr-taylor-rule': '/reports/macro/gr_taylor_rule',
  'gr-trade-monitor': '/reports/macro/gr_trade_monitor',
  'healthcare-valuation': '/reports/healthcare/valuation',
  'high-frequency-google-trends': '/reports/macro/high_frequency_google_trends',
  'high-frequency-truflation': '/reports/macro/high_frequency_truflation',
  homebuilders: '/reports/homebuilders',
  industrials: '/reports/industrials',
  'insider-trading': '/reports/insider_trading',
  insurance: '/reports/insurance',
  lithium: '/reports/lithium',
  'macro-report': '/reports/macro_report',
  'macroeconomic-series': '/reports/macro/macroeconomic_series',
  'market-structure': '/reports/market_structure',
  'metals-and-mining': '/reports/metalsandmining/analysis',
  'nlp-models': '/reports/nlp_models',
  oil: '/reports/oil/analysis',
  overview: '/reports/overview',
  payments: '/reports/payments',
  'pair-trading': '/reports/pair_trading',
  'portfolio-analysis': '/reports/portfolio_analysis',
  positions: '/reports/positions',
  proteins: '/reports/proteins/analysis',
  'proteins-aus-beef': '/reports/proteins/aus_beef',
  'proteins-bz-beef': '/reports/proteins/bz_beef',
  'proteins-bz-chicken': '/reports/proteins/bz_chicken',
  'proteins-global-markets': '/reports/proteins/global_markets',
  'proteins-us-beef': '/reports/proteins/us_beef',
  'proteins-us-chicken': '/reports/proteins/us_chicken',
  'proteins-us-pork': '/reports/proteins/us_pork',
  'proteins-valuation': '/reports/proteins/valuation',
  'pulp-and-paper': '/reports/pulp_and_paper/analysis',
  'quant-strategy': '/reports/quant_strategy',
  retail: '/reports/retail',
  saas: '/reports/saas',
  satellite: '/reports/satellite',
  'short-squeeze-macro': '/reports/short_squeeze/macro',
  sugar: '/reports/sugar/analysis',
  surprise: '/reports/surprise',
  'tech-disruption': '/reports/tech_disruption',
  telecom: '/reports/telecom',
  'test-controls': '/reports/test_controls',
  test: '/reports/test',
  trading: '/reports/trading',
  transcripts: '/reports/transcripts',
  upside: '/reports/upside',
  utilities: '/reports/utilities',
  'us-cpi': '/reports/macro/us_cpi',
  'us-fomc': '/reports/macro/us_fomc',
  'us-inflation-expectations': '/reports/macro/us_inflation_expectations',
  'us-payroll': '/reports/macro/us_payroll',
  'us-pce-inflation': '/reports/macro/us_pce_inflation',
  'us-personal-income': '/reports/macro/us_personal_income',
  'us-recession-probability': '/reports/macro/us_recession_probability',
  'us-regions-fed-surveys': '/reports/macro/us_regions_fed_surveys',
  'us-retail-sales': '/reports/macro/us_retail_sales',
  'valuation-report': '/reports/valuation_report',
  'valuation-spx': '/reports/valuation_spx',
} as const
